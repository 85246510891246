import React from "react"
import News from "../../../blocks/en/news_page"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

export const pageQuery = graphql`
query {
  news: newsYaml(slug: { eq: "saveljevs_latvia_u21" }) {
    ...NewsInfoEn
    innerImages {
      callSheet {
        childImageSharp {
          fluid(maxWidth: 1680) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
}`

export default class NewsTextEn extends News {
  renderNews() {
    const { callSheet } = this.props.data.news.innerImages
    return (
      <div className="row">
        <div className="col-xl-12">
          <p><Link to={ `/en/players/saveljevs` }>Aleksejs Saveljevs</Link>, a midfielder of Hellas Verona, is called up to the Latvia U21 national team to take part in European Championship U-21 qualifiers against the national teams of Poland (06.09.2019) and Serbia (10.09.2019).</p>

          <Img fluid={ callSheet.childImageSharp.fluid } alt="Saveljevs in Latvia U-21" width="100%" />

        </div>
      </div>
    )
  }
}